import React from "react";
import { useHistory } from "react-router-dom";
import styles from "./Offices.module.scss";
import * as paths from "features/routing/paths";
import { LegacyCard } from "components/LegacyCard";
import { Button } from "components/Button";
import { Layout } from "features/layout";

interface OfficeProps {
  name: string;
  officeNumber: string;
}
//chnage list as top result has no apts
const OFFICE_LIST: OfficeProps[] = [
  { name: "VA - Potomac Mills", officeNumber: "MED-008" },
  { name: "MD - GermanTown", officeNumber: "MED-035" },
  { name: "GA - Kennesaw", officeNumber: "MED-397" },
  { name: "WI - New Berlin", officeNumber: "MED-499" },
  { name: "WI - Fox Point", officeNumber: "MED-565" },
  { name: "WI - West Side", officeNumber: "MED-586" },
  { name: "IL - Andersonville", officeNumber: "MED-172" },
  { name: "WI - Cudahy", officeNumber: "MED-701" },
  { name: "NC - Greensboro-ElmStreet", officeNumber: "MED-083" },
  { name: "NC - Cape Carteret", officeNumber: "MED-414" },
  { name: "Test007", officeNumber: "MED-007" },
  { name: "NC - New Bern", officeNumber: "MED-506" },
  { name: "CT - Avon", officeNumber: "MED-349" },
  { name: "FL - Winter Haven", officeNumber: "MED-134" },
  { name: "AL - Hoover", officeNumber: "MED-299" },
  { name: "IN - Terre Haute", officeNumber: "MED-387" },
  { name: "SC - Pickens", officeNumber: "MED-347" },
  { name: "SC - Mills Ave", officeNumber: "MED-429" },
  { name: "SC - Greenvillle-WadeHampton", officeNumber: "MED-096" },
  { name: "SC - Greer", officeNumber: "MED-073" },
  { name: "TX - Lakewood", officeNumber: "MED-727" },
  { name: "Test Office", officeNumber: "MED-999" },
];

const Office: React.FC<OfficeProps> = ({ name, officeNumber }) => {
  const history = useHistory();

  return (
    <div className={styles.office} data-office-number={officeNumber}>
      <div className={styles.officeName}>
        {name} ({officeNumber})
      </div>

      <Button
        text="Book Appointment"
        onClick={() => {
          history.push(
            paths.appointmentBookingStart({ officeId: officeNumber })
          );
        }}
      />
    </div>
  );
};

export const Offices: React.FC = () => {
  return (
    <Layout>
      <LegacyCard>
        <div className={styles.container}>
          <h1>Offices</h1>

          {OFFICE_LIST.map((officeProps) => (
            <Office key={officeProps.officeNumber} {...officeProps} />
          ))}
        </div>
      </LegacyCard>
    </Layout>
  );
};
